<template>
  <div class="d-flex flex-column align-items-center mb-1">
    <strong >{{message}}</strong>
    <b-spinner variant="primary" label="Loading..." />
  </div>
</template>

<script>
import { BSpinner, } from 'bootstrap-vue'

export default {
  components: {
    BSpinner ,
  },
  props: {
    message: {
      type: String,
      default: "Loading...",
    }
  }
}
</script>

<style lang="scss" scoped>

</style>